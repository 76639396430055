// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

  $('.js-replace-img').each(function() {

    var src = $(this).find("img").attr("src");
    $(this).css("background-image","url("+src+")");
  });
};

jQuery(function() {
  if($(".js-replace-img")) {
    replaceSrc();
  }
});

// HIDE HEADER ON SCROLL DOWN
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('.navbar').outerHeight();

$(window).scroll(function(event){
    didScroll = true;
});

setInterval(function() {
    if (didScroll) {
        hasScrolled();
        didScroll = false;
    }
}, 250);

function hasScrolled() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('.main-header ').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('.main-header ').removeClass('nav-up').addClass('nav-down');
        }
    }

    lastScrollTop = st;

}

// Responsive menu
jQuery('#btnMobile').click(function() {
  jQuery(this).toggleClass('cross');
  jQuery('.nav-wrapper').toggleClass('show');
  jQuery('body').toggleClass('overflow');
});

// Responsive menu - subnav
jQuery('#sub-nav').click(function() {
  jQuery(this).toggleClass('active');
  jQuery('#aside ul').toggleClass('show');
});

// Carrousel
(function() {
 var c = {

   carrouselInit: function() {

     $(".js-carrousel").each(function() {

       var slideshow = $(this);
       slideshow.attr("data-current",0);
       var type = slideshow.attr("data-type");
       var loop = slideshow.attr("data-loop");
       var effect = slideshow.attr("data-effect");
       var wrapper = slideshow.find(".js-carrousel-wrapper");
       var slides = slideshow.find(".js-carrousel-slide");
       var controller = slideshow.find(".js-carrousel-controllers");
       var cType = controller.attr("data-type");

       c.slidesInit(wrapper,slides,type,loop,effect);
       c.controllerInit(slideshow,controller,cType);
     });

     if($('.js-carrousel-modal')) {

       $(".js-carrousel-modal").each(function() {
         var t = $(this);
         var close = $("<button>close</button>").addClass("js-carrousel-close");
         close.click(function() {t.removeClass("show")});
         $(this).append(close);
       });
     }
   },

   controllerInit: function(slideshow,controller,type) {

     if(type == "switch") {

       var next = $("<span></span>").addClass("js-carrousel-next").text("next");
       var prev = $("<span></span>").addClass("js-carrousel-prev").text("previous");
       controller.append(next,prev);
       $(next).on("click",function() {c.switching(slideshow,'next');});
       $(prev).on("click",function() {c.switching(slideshow,'prev');});
     }
   },

   slidesInit: function(wrapper,slides,type,loop,effect) {

     slides.each(function(i) {

       var img = $(this).find("img");
       var imgPath = img.attr("src");

       if(effect == "zoom") {

         var span = $("<span></span>");
         span.css("background-image","url("+imgPath+")");
         $(this).find(".js-carrousel-image").append(span);

       } else {

         $(this).find(".js-carrousel-image").css("background-image","url("+imgPath+")");
       }

       if(type === "multiple") {

         var k = 100*i;
         $(this).css("transform",'translate('+ k +'%,0)');
       }
     });

     if(loop) {

       var first = slides.eq(0).clone();
       var last = slides.eq(-1).clone();
       first.css("transform",'translate('+ slides.length * 100 +'%,0)');
       last.css("transform",'translate('+ -100 +'%,0)');
       wrapper.prepend(last);
       wrapper.append(first);
     }
   },

   updatePosition: function(slideshow) {

     var k = slideshow.attr('data-current');
     var slides = slideshow.find(".js-carrousel-slide");

     slides.each(function(i) {

       $(this).css("transform","translate("+ ((i*100) - 100) +"%,0)");
     });
   },

   switching: function(slideshow,d) {

     var current = parseInt(slideshow.attr('data-current'));
     var slides = slideshow.find(".js-carrousel-slide");
     var wrapper = slideshow.find(".js-carrousel-wrapper");
     var k;
     if(d === "next") {

       k = -1;

       var first = slides.eq(2).clone();
       slides.eq(0).remove();
       first.css("transform",'translate('+ slides.length * 100 +'%,0)');
       wrapper.append(first);

     } else {

       k = 1;

       var last = slides.eq(-3).clone();
       slides.eq(-1).remove();
       last.css("transform",'translate('+ -100 +'%,0)');
       wrapper.prepend(last);
     }

     current += k;
     slideshow.attr("data-current",current);

     c.updatePosition(slideshow);
   },

   events: function() {

     if($(".js-carrousel-open")) {

       $(".js-carrousel-open").click(function() {
         var carrousel = $(this).attr("data-carrousel");
         $(".js-carrousel-modal").each(function(){
           if( $(this).attr("data-carrousel") == carrousel) {

             $(this).addClass("show");
           }
         });
       });
     }
   },

   init: function() {

     this.events();
     this.carrouselInit();
   }
 };

 c.init();
})();
